.advantages {
}

.advantages__list {
  @include reset-ul;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;

  @media (max-width: $breakpoint-desktop) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.advantages__item {
  padding: 0 15px;
  background-color: #fff;
  @media (max-width: $breakpoint-tablet) {
    padding: 0;
    text-align: justify;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: bold;
    padding: 5px;
    background-color: #a4c4c8;
    color: $white-color;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1rem;
      padding: 4px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.9rem;
      padding: 3px;
    }
  }

  p {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 15px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.75rem;
      margin-bottom: 8px;
    }
  }

  ul {
    li {
      font-size: 0.9rem;
      margin-bottom: 8px;
      padding-left: 20px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        background-color: $primary-color;
        border-radius: 50%;
        top: calc(50% - 3px);
        left: 0;
      }

      @media (max-width: $breakpoint-tablet) {
        font-size: 0.8rem;
        margin-bottom: 6px;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 0.75rem;
        margin-bottom: 5px;
      }
    }
  }

  &:nth-child(2) {
    h2 {
      background-color: #4f7f7f;
      color: white;
    }
  }

  &:nth-child(3) {
    h2 {
      background-color: #e0e0e0;
    }
  }

  &:nth-child(4) {
    h2 {
      background-color: #dc143c;
      color: white;
    }

    .advantages__call {
      cursor: pointer;
      display: inline-block;
      padding: 10px 20px;
      background-color: white;
      color: black;
      text-decoration: none;
      margin: 25px auto;
      font-weight: 500;
      border: 2px solid black;

      @media (max-width: $breakpoint-tablet) {
        padding: 8px 16px;
        font-size: 0.9rem;
      }

      @media (max-width: $breakpoint-mobile) {
        padding: 6px 12px;
        font-size: 0.8rem;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    p:not(:first-of-type) {
      margin-top: 5px;
      margin-bottom: 5px;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }
}

.advantages__link {
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  margin-bottom: 10px;

  &:hover {
    background-color: #e7f5f5;
  }

  @media (max-width: $breakpoint-tablet) {
    font-size: 0.9rem;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .advantages__list {
    grid-template-columns: 1fr;
  }

  .advantages__item {
    margin-bottom: 20px;
  }
}
