.page {
  box-sizing: border-box;
  min-width: $breakpoint-mobile;

  margin: 0;
  height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: $font-size-basic;
  line-height: $line-height-basic;
  font-family: $font-primary;
  color: $primary-color;
  letter-spacing: $letter-spacing-basic;

  scroll-behavior: smooth;

  ul {
    @include reset-ul;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
}
.page__body {
  margin: 15px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__title {
  @include visually-hidden;
}

.page__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 30px;
}

.page a,
.page button {
  user-select: none;
  color: inherit;
}

.page h2,
h3 {
  font-family: $font-secondary;
}

.page * {
  box-sizing: border-box;
}
