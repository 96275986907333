.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.is-active {
    display: flex;
    opacity: 1;
  }

  &__content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 4px;
    max-width: 500px;
    width: 90%;
    margin: auto;
    transform: translateY(-20px);
    transition: transform 0.3s ease;

    .is-active & {
      transform: translateY(0);
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
    color: #d4c6c6;

    &:hover {
      color: #000;
    }
  }

  &__form {
    margin-top: 20px;

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
      }

      input,
      textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;

        &:focus {
          outline: none;
          border-color: #009b9b;
        }
      }
    }

    .button {
      width: 100%;
      padding: 12px;
      background-color: #009b9b;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken(#009b9b, 10%);
      }
    }
  }
}

body.modal-open {
  overflow: hidden;
}
