.container {
  padding: $container-padding-mobile;
  max-width: $container-max-width;
  margin: 0 auto;

  @media (min-width: $breakpoint-tablet) {
    padding: $container-padding-desktop;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: $container-padding-desktop;
  }

  @media (min-width: $breakpoint-large) {
    padding: $container-padding-desktop;
  }
}
.container--columns {
  display: grid;
  grid-template-columns: 180px auto 236px;
  gap: 56px;
}

@media (max-width: $breakpoint-tablet) {
  .container {
    text-align: justify;
  }
  .container--columns {
    grid-template-columns: 1fr;
  }
}
