@mixin reset-ul() {
    margin: 0;
    padding: 0;

    list-style: none;
    list-style-type: none;
}

@mixin visually-hidden() {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
}

@mixin container() {
    width: 100%;
    max-width: $vp-desktop;
    margin: 0 auto;
    padding: 30px;
}
