.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 3px;
  border-bottom: 7px solid #dbdddc;
  height: 120px;

  @media (max-width: $breakpoint-desktop) {
    height: 100px;
    padding-bottom: 2px;
  }

  @media (max-width: $breakpoint-tablet) {
    height: auto;
    border-bottom: 5px solid #dbdddc;
  }

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: 1px;
  }
}

.header__nav {
  width: 47%;
  margin-left: auto;
  padding-right: 30px;
  display: flex;

  @media (max-width: $breakpoint-desktop) {
    width: 65%;
    margin-left: auto;
  }

  @media (max-width: $breakpoint-tablet) {
    &--active {
      display: flex;
    }
    top: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 99;

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }

    li {
      text-align: center;
      padding: 10px;
    }
  }

  a {
    font-size: 1.6rem;
    padding: 0 10px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.4rem;
      padding: 0 8px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.2rem;
      padding: 0 5px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
}

.header__logo {
  // pointer-events: none;
  width: 232px;
  height: 118px;

  @media (max-width: $breakpoint-desktop) {
    width: 200px;
    height: 100px;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 150px;
    height: auto;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 100px;
  }
}

.header__slogan {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  color: #878988;
  line-height: 2rem;
  padding-left: 20px;
  font-size: 18px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 16px;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 14px;
    line-height: 1.8rem;
  }
}