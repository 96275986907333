.intro {
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 54%;
  gap: 20px;

  p {
    background-color: $white-color;
    padding-right: 50px;

    @media (max-width: $breakpoint-tablet) {
      padding-right: 30px;
      font-size: 1rem;
    }

    @media (max-width: $breakpoint-mobile) {
      padding-right: 20px;
      font-size: 0.9rem;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.intro__image {
  img {
    width: 100%;
    height: auto;
    border-radius: 0 0 0 38%;

    @media (max-width: $breakpoint-tablet) {
      border-radius: 0 0 0 25%;
    }

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0 0 0 15%;
    }
  }
}
.intro__title {
  height: 200px;
  position: relative;

  h2 {
    overflow: visible;
    width: 500px;
    left: 30%;
    position: absolute;
    padding: 10px;
    padding-right: 60px;
    background-color: $white-color;
    color: #40a9be;
    line-height: 2.8rem;
    font-weight: 350;
    padding-left: 38px;
    font-size: 28px;

    @media (max-width: $breakpoint-tablet) {
      width: 80%;
      left: 10%;
      font-size: 1.8rem;
      padding-left: 20px;
      padding-right: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 90%;
      left: 5%;
      font-size: 1.5rem;
      padding-left: 15px;
      padding-right: 30px;
    }
  }
}
