.facades {
  margin-top: 20px;

  img {
    border-radius: 0 0 0 50%;
    margin-bottom: 50px;

    @media (max-width: $breakpoint-desktop) {
      border-radius: 0 0 0 40%;
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-tablet) {
      border-radius: 0 0 0 30%;
      margin-bottom: 30px;
      display: none;
    }

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0 0 0 20%;
      margin-bottom: 20px;
    }
  }

  h3 {
    background-color: $color-1;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px;
    padding-left: 10px;
    margin: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 13px;
      padding: 4px;
      padding-left: 8px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 12px;
      padding: 3px;
      padding-left: 5px;
    }
  }
}

.facades__article {
  margin-left: 50px;

  @media (max-width: $breakpoint-desktop) {
    margin-left: 40px;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-left: 20px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-left: 10px;
  }
}

.facades__ads {
  font-size: 16px;
  width: 214px;
  padding: 10px 20px;
  border: 1px solid $color-border;

  @media (max-width: $breakpoint-tablet) {
    font-size: 14px;
    width: 100%;
    padding: 8px 16px;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 12px;
    width: 160px;
    padding: 6px 12px;
  }

  p {
    color: $color-link;
    margin: 0;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 20px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 15px;
    }
  }

  ul {
    padding-left: 10px;
    @media (max-width: $breakpoint-tablet) {
      padding-left: 50px;
    }
    @media (max-width: $breakpoint-mobile) {
      padding-left: 8px;
    }
  }

  li {
    cursor: pointer;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 13px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 12px;
      max-width: 200px;
      text-align: left;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 11px;
      margin-bottom: 4px;
    }
  }

  .active {
    background-color: #cccccc;

    @media (max-width: $breakpoint-mobile) {
      background-color: #e0e0e0;
    }
  }
}
