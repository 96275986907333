.nav {
  ul {
    @include reset-ul;
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: center;
    }
  }

  a {
    font-size: 21px;
    text-decoration: none;
    line-height: normal;

    &:hover {
      color: #40a9be;
      border-bottom: #40a9be solid 3px;
    }

    &:active {
      font-weight: $font-weight-semibold;
    }

    @media (max-width: $breakpoint-desktop) {
      font-size: 18px;
    }

    @media (max-width: $breakpoint-tablet) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
    }
  }
}

.nav__link--active {
  font-weight: $font-weight-semibold;

  @media (max-width: $breakpoint-mobile) {
    font-size: 15px;
  }
}
