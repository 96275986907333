.projects {
  margin-top: 20px;

  img {
    border-radius: 0 0 0 50%;
    margin-bottom: 50px;

    @media (max-width: $breakpoint-tablet) {
      border-radius: 0 0 0 40%;
      margin-bottom: 40px;
      display: none;
    }

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0 0 0 30%;
      margin-bottom: 30px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px;
    padding-left: 10px;
    margin: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 13px;
      padding-left: 8px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 12px;
      padding-left: 6px;
    }
  }

  p {
    margin-bottom: 80px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 60px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 40px;
    }
  }

  section:nth-child(1) {
    h3 {
      background-color: $color-5;
    }
  }

  section:nth-child(2) {
    h3 {
      background-color: $color-6;
    }
  }

  section:nth-child(3) {
    h3 {
      background-color: $color-7;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    section {
      margin-bottom: 30px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    section {
      margin-bottom: 20px;
    }
  }
}
