.about {
  margin-top: 20px;

  img {
    border-radius: 0 0 0 40%;
    margin-bottom: 50px;

    @media (max-width: $breakpoint-desktop) {
      border-radius: 0 0 0 30%;
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-tablet) {
      border-radius: 0 0 0 20%;
      margin-bottom: 30px;
      margin: auto;
    }

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0 0 0 10%;
      margin-bottom: 20px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px;
    padding-left: 10px;
    margin: 0;
    font-weight: $font-weight-bold;
    background-color: $color-1;

    @media (max-width: $breakpoint-desktop) {
      font-size: 14px;
      padding: 4px;
      padding-left: 8px;
    }

    @media (max-width: $breakpoint-tablet) {
      font-size: 13px;
      padding: 3px;
      padding-left: 6px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 12px;
      padding: 2px;
      padding-left: 5px;
    }
  }

  .about__note {
    background-color: #f5f5f5;
    border: 1px solid #aebcbc;
    height: fit-content;
    padding: 15px;
    & h3 {
      background-color: #f5f5f5;
      padding: 0;
      border-bottom: 1px solid #dbdddc;
    }
  }
}
