.footer {
  margin-top: 20px;
  background-color: $color-footer;

  .footer__2toucans {
    cursor: pointer;
    color: #0062a0;

    &:hover {
      border-bottom: 1px solid $color-link;
      color: $color-link;
    }

    @media (max-width: $breakpoint-tablet) {
      font-size: 0.9rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.8rem;
    }
  }

  p {
    padding-top: 5px;
    padding-bottom: 5px;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 0.9rem;
    }

    @media (max-width: $breakpoint-mobile) {
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 0.8rem;
    }
  }
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: $breakpoint-desktop) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
