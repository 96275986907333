.tabs {
  position: relative;
  margin-top: 20px;
}

.tabs__controls {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;

  position: absolute;
  top: 0;
  left: 20%;

  @media (max-width: $breakpoint-tablet) {
    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    left: 0;
    gap: 8px;
  }

  @media (max-width: $breakpoint-mobile) {
    left: 5%; // Сдвигаем еще сильнее для мобильных
    gap: 5px;
  }
}

.tabs__controls li {
  &:nth-child(1) {
    background-color: $color-1;
  }
  &:nth-child(2) {
    background-color: $color-2;
  }
  &:nth-child(3) {
    background-color: $color-3;
  }
  &:nth-child(4) {
    background-color: $color-4;
  }
  border-radius: 10px;
  padding: 3px;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid transparent;
  font-weight: $font-weight-medium;
  font-size: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;

  @media (max-width: $breakpoint-tablet) {
    font-size: 14px;
    padding: 5px;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 13px;
    padding: 4px;
  }
}

.tabs__controls li:hover {
  background-color: #e0e0e0;
}

.tabs__controls li.active {
  font-weight: $font-weight-bold;
}

.tab-content {
  display: none;
  background-color: #fff;
  border-radius: 5px;

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.tab-content.active {
  display: grid;
  grid-template-columns: 150px auto 236px;
  gap: 86px;

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.tab-content aside:first-of-type {
  img {
    border-radius: 0 0 0 50%;
  }
}

.tab-content aside ul {
  list-style: none;
  padding: 0;
}

.tab-content aside ul li {
  margin-bottom: 10px;
}

.tab-content aside ul li img {
  display: block;
  width: 100%;
  height: auto;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  margin-bottom: 40px;
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.tab-content article h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdddc;
}

.tab-content article p {
  margin-bottom: 15px;
  color: #333;
}

@media (max-width: 768px) {
  .tabs__controls {
    flex-wrap: wrap;
    justify-content: center; // Выравнивание кнопок управления по центру
  }

  .tab-content {
    flex-direction: column; // Контент в колонку на маленьких экранах
  }
}

.tab__text {
  margin-top: 50px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 30px; // Уменьшение отступа для планшетов
  }

  @media (max-width: $breakpoint-mobile) {
    margin-top: 20px; // Уменьшение отступа для мобильных устройств
  }
}
