.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
}
.wrapper .header,
.wrapper .footer {
  flex-shrink: 0;
}
.wrapper main {
  flex-grow: 1;
}

.container {
  padding: auto;
  max-width: 1200px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .container {
    padding: auto;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: auto;
  }
}
@media (min-width: 1920px) {
  .container {
    padding: auto;
  }
}

.container--columns {
  display: grid;
  grid-template-columns: 180px auto 236px;
  gap: 56px;
}

@media (max-width: 768px) {
  .container {
    text-align: justify;
  }
  .container--columns {
    grid-template-columns: 1fr;
  }
}
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal.is-active {
  display: flex;
  opacity: 1;
}
.modal__content {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 4px;
  max-width: 500px;
  width: 90%;
  margin: auto;
  transform: translateY(-20px);
  transition: transform 0.3s ease;
}
.is-active .modal__content {
  transform: translateY(0);
}
.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  color: #d4c6c6;
}
.modal__close:hover {
  color: #000;
}
.modal__form {
  margin-top: 20px;
}
.modal__form .form-group {
  margin-bottom: 20px;
}
.modal__form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}
.modal__form .form-group input,
.modal__form .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.modal__form .form-group input:focus,
.modal__form .form-group textarea:focus {
  outline: none;
  border-color: #009b9b;
}
.modal__form .button {
  width: 100%;
  padding: 12px;
  background-color: #009b9b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.modal__form .button:hover {
  background-color: #006868;
}

body.modal-open {
  overflow: hidden;
}

.page {
  box-sizing: border-box;
  min-width: 375px;
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
  background-color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5rem;
  font-family: "Verdana", "Helvetica", "Arial", sans-serif;
  color: #313131;
  letter-spacing: 0;
  scroll-behavior: smooth;
}
.page ul {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.page ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.page ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.page ::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.page ::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

.page__body {
  margin: 15px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__title {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.page__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 30px;
}

.page a,
.page button {
  user-select: none;
  color: inherit;
}

.page h2,
h3 {
  font-family: "Raleway", "Times New Roman", serif;
}

.page * {
  box-sizing: border-box;
}

.burger-menu {
  display: none;
  position: absolute;
  top: 50px;
  right: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;
  width: 40px;
  height: 40px;
}
.burger-menu .burger-icon {
  width: 30px;
  height: 4px;
  background-color: #333;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease;
}
.burger-menu .burger-icon::before, .burger-menu .burger-icon::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #333;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.burger-menu .burger-icon::before {
  top: -10px;
  left: -10px;
}
.burger-menu .burger-icon::after {
  top: 10px;
  left: -10px;
}
.burger-menu.active .burger-icon {
  background-color: transparent;
  opacity: 0.3;
}
.burger-menu.active .burger-icon::before {
  transform: rotate(45deg);
  top: 0;
}
.burger-menu.active .burger-icon::after {
  transform: rotate(-45deg);
  top: 0;
}
@media (max-width: 768px) {
  .burger-menu {
    display: block;
  }
}

.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .nav ul {
    flex-direction: column;
    align-items: center;
  }
}
.nav a {
  font-size: 21px;
  text-decoration: none;
  line-height: normal;
}
.nav a:hover {
  color: #40a9be;
  border-bottom: #40a9be solid 3px;
}
.nav a:active {
  font-weight: 600;
}
@media (max-width: 1200px) {
  .nav a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .nav a {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media (max-width: 375px) {
  .nav a {
    font-size: 14px;
  }
}

.nav__link--active {
  font-weight: 600;
}
@media (max-width: 375px) {
  .nav__link--active {
    font-size: 15px;
  }
}

.intro {
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 54%;
  gap: 20px;
}
.intro p {
  background-color: #ffffff;
  padding-right: 50px;
}
@media (max-width: 768px) {
  .intro p {
    padding-right: 30px;
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .intro p {
    padding-right: 20px;
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .intro {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
@media (max-width: 375px) {
  .intro {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.intro__image img {
  width: 100%;
  height: auto;
  border-radius: 0 0 0 38%;
}
@media (max-width: 768px) {
  .intro__image img {
    border-radius: 0 0 0 25%;
  }
}
@media (max-width: 375px) {
  .intro__image img {
    border-radius: 0 0 0 15%;
  }
}

.intro__title {
  height: 200px;
  position: relative;
}
.intro__title h2 {
  overflow: visible;
  width: 500px;
  left: 30%;
  position: absolute;
  padding: 10px;
  padding-right: 60px;
  background-color: #ffffff;
  color: #40a9be;
  line-height: 2.8rem;
  font-weight: 350;
  padding-left: 38px;
  font-size: 28px;
}
@media (max-width: 768px) {
  .intro__title h2 {
    width: 80%;
    left: 10%;
    font-size: 1.8rem;
    padding-left: 20px;
    padding-right: 40px;
  }
}
@media (max-width: 375px) {
  .intro__title h2 {
    width: 90%;
    left: 5%;
    font-size: 1.5rem;
    padding-left: 15px;
    padding-right: 30px;
  }
}

.advantages__list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
}
@media (max-width: 1200px) {
  .advantages__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media (max-width: 375px) {
  .advantages__list {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.advantages__item {
  padding: 0 15px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .advantages__item {
    padding: 0;
    text-align: justify;
  }
}
.advantages__item h2 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: bold;
  padding: 5px;
  background-color: #a4c4c8;
  color: #ffffff;
}
@media (max-width: 768px) {
  .advantages__item h2 {
    font-size: 1rem;
    padding: 4px;
  }
}
@media (max-width: 375px) {
  .advantages__item h2 {
    font-size: 0.9rem;
    padding: 3px;
  }
}
.advantages__item p {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .advantages__item p {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 375px) {
  .advantages__item p {
    font-size: 0.75rem;
    margin-bottom: 8px;
  }
}
.advantages__item ul li {
  font-size: 0.9rem;
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}
.advantages__item ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #313131;
  border-radius: 50%;
  top: calc(50% - 3px);
  left: 0;
}
@media (max-width: 768px) {
  .advantages__item ul li {
    font-size: 0.8rem;
    margin-bottom: 6px;
  }
}
@media (max-width: 375px) {
  .advantages__item ul li {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
}
.advantages__item:nth-child(2) h2 {
  background-color: #4f7f7f;
  color: white;
}
.advantages__item:nth-child(3) h2 {
  background-color: #e0e0e0;
}
.advantages__item:nth-child(4) h2 {
  background-color: #dc143c;
  color: white;
}
.advantages__item:nth-child(4) .advantages__call {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: black;
  text-decoration: none;
  margin: 25px auto;
  font-weight: 500;
  border: 2px solid black;
}
@media (max-width: 768px) {
  .advantages__item:nth-child(4) .advantages__call {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .advantages__item:nth-child(4) .advantages__call {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
.advantages__item:nth-child(4) p:last-of-type {
  margin-bottom: 0;
}
.advantages__item:nth-child(4) p:not(:first-of-type) {
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (max-width: 375px) {
  .advantages__item:nth-child(4) p:not(:first-of-type) {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.advantages__link {
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  margin-bottom: 10px;
}
.advantages__link:hover {
  background-color: #e7f5f5;
}
@media (max-width: 768px) {
  .advantages__link {
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .advantages__link {
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .advantages__list {
    grid-template-columns: 1fr;
  }
  .advantages__item {
    margin-bottom: 20px;
  }
}
.footer {
  margin-top: 20px;
  background-color: #e9eeec;
}
.footer .footer__2toucans {
  cursor: pointer;
  color: #0062a0;
}
.footer .footer__2toucans:hover {
  border-bottom: 1px solid #40a9be;
  color: #40a9be;
}
@media (max-width: 768px) {
  .footer .footer__2toucans {
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .footer .footer__2toucans {
    font-size: 0.8rem;
  }
}
.footer p {
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (max-width: 768px) {
  .footer p {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .footer p {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 0.8rem;
  }
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .footer__wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .footer__wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 3px;
  border-bottom: 7px solid #dbdddc;
  height: 120px;
}
@media (max-width: 1200px) {
  .header__wrapper {
    height: 100px;
    padding-bottom: 2px;
  }
}
@media (max-width: 768px) {
  .header__wrapper {
    height: auto;
    border-bottom: 5px solid #dbdddc;
  }
}
@media (max-width: 375px) {
  .header__wrapper {
    padding-bottom: 1px;
  }
}

.header__nav {
  width: 47%;
  margin-left: auto;
  padding-right: 30px;
  display: flex;
}
@media (max-width: 1200px) {
  .header__nav {
    width: 65%;
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .header__nav {
    top: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }
  .header__nav--active {
    display: flex;
  }
  .header__nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  .header__nav li {
    text-align: center;
    padding: 10px;
  }
}
.header__nav a {
  font-size: 1.6rem;
  padding: 0 10px;
}
@media (max-width: 768px) {
  .header__nav a {
    font-size: 1.4rem;
    padding: 0 8px;
  }
}
@media (max-width: 375px) {
  .header__nav a {
    font-size: 1.2rem;
    padding: 0 5px;
  }
}
@media (max-width: 768px) {
  .header__nav {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
}

.header__logo {
  width: 232px;
  height: 118px;
}
@media (max-width: 1200px) {
  .header__logo {
    width: 200px;
    height: 100px;
  }
}
@media (max-width: 768px) {
  .header__logo {
    width: 150px;
    height: auto;
  }
}
@media (max-width: 375px) {
  .header__logo {
    width: 100px;
  }
}

.header__slogan {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  color: #878988;
  line-height: 2rem;
  padding-left: 20px;
  font-size: 18px;
}
@media (max-width: 768px) {
  .header__slogan {
    font-size: 16px;
  }
}
@media (max-width: 375px) {
  .header__slogan {
    font-size: 14px;
    line-height: 1.8rem;
  }
}

.tabs {
  position: relative;
  margin-top: 20px;
}

.tabs__controls {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: 20%;
}
@media (max-width: 768px) {
  .tabs__controls {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    left: 0;
    gap: 8px;
  }
}
@media (max-width: 375px) {
  .tabs__controls {
    left: 5%;
    gap: 5px;
  }
}

.tabs__controls li {
  border-radius: 10px;
  padding: 3px;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid transparent;
  font-weight: 500;
  font-size: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.tabs__controls li:nth-child(1) {
  background-color: #a4c8d4;
}
.tabs__controls li:nth-child(2) {
  background-color: #67999a;
}
.tabs__controls li:nth-child(3) {
  background-color: #aebcbc;
}
.tabs__controls li:nth-child(4) {
  background-color: #d7e0df;
}
@media (max-width: 768px) {
  .tabs__controls li {
    font-size: 14px;
    padding: 5px;
  }
}
@media (max-width: 375px) {
  .tabs__controls li {
    font-size: 13px;
    padding: 4px;
  }
}

.tabs__controls li:hover {
  background-color: #e0e0e0;
}

.tabs__controls li.active {
  font-weight: 700;
}

.tab-content {
  display: none;
  background-color: #fff;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .tab-content {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .tab-content {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.tab-content.active {
  display: grid;
  grid-template-columns: 150px auto 236px;
  gap: 86px;
}
@media (max-width: 768px) {
  .tab-content.active {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
@media (max-width: 375px) {
  .tab-content.active {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.tab-content aside:first-of-type img {
  border-radius: 0 0 0 50%;
}

.tab-content aside ul {
  list-style: none;
  padding: 0;
}

.tab-content aside ul li {
  margin-bottom: 10px;
}

.tab-content aside ul li img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .tab-content aside ul li img {
    display: none;
  }
}

.tab-content article h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdddc;
}

.tab-content article p {
  margin-bottom: 15px;
  color: #333;
}

@media (max-width: 768px) {
  .tabs__controls {
    flex-wrap: wrap;
    justify-content: center;
  }
  .tab-content {
    flex-direction: column;
  }
}
.tab__text {
  margin-top: 50px;
}
@media (max-width: 768px) {
  .tab__text {
    margin-top: 30px;
  }
}
@media (max-width: 375px) {
  .tab__text {
    margin-top: 20px;
  }
}

.projects {
  margin-top: 20px;
}
.projects img {
  border-radius: 0 0 0 50%;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .projects img {
    border-radius: 0 0 0 40%;
    margin-bottom: 40px;
    display: none;
  }
}
@media (max-width: 375px) {
  .projects img {
    border-radius: 0 0 0 30%;
    margin-bottom: 30px;
  }
}
.projects h3 {
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px;
  padding-left: 10px;
  margin: 0;
}
@media (max-width: 768px) {
  .projects h3 {
    font-size: 13px;
    padding-left: 8px;
  }
}
@media (max-width: 375px) {
  .projects h3 {
    font-size: 12px;
    padding-left: 6px;
  }
}
.projects p {
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .projects p {
    margin-bottom: 60px;
  }
}
@media (max-width: 375px) {
  .projects p {
    margin-bottom: 40px;
  }
}
.projects section:nth-child(1) h3 {
  background-color: #a4c4c8;
}
.projects section:nth-child(2) h3 {
  background-color: #d4ddd9;
}
.projects section:nth-child(3) h3 {
  background-color: #91a5a6;
}
@media (max-width: 768px) {
  .projects section {
    margin-bottom: 30px;
  }
}
@media (max-width: 375px) {
  .projects section {
    margin-bottom: 20px;
  }
}

.about {
  margin-top: 20px;
}
.about img {
  border-radius: 0 0 0 40%;
  margin-bottom: 50px;
}
@media (max-width: 1200px) {
  .about img {
    border-radius: 0 0 0 30%;
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .about img {
    border-radius: 0 0 0 20%;
    margin-bottom: 30px;
    margin: auto;
  }
}
@media (max-width: 375px) {
  .about img {
    border-radius: 0 0 0 10%;
    margin-bottom: 20px;
  }
}
.about h3 {
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px;
  padding-left: 10px;
  margin: 0;
  font-weight: 700;
  background-color: #a4c8d4;
}
@media (max-width: 1200px) {
  .about h3 {
    font-size: 14px;
    padding: 4px;
    padding-left: 8px;
  }
}
@media (max-width: 768px) {
  .about h3 {
    font-size: 13px;
    padding: 3px;
    padding-left: 6px;
  }
}
@media (max-width: 375px) {
  .about h3 {
    font-size: 12px;
    padding: 2px;
    padding-left: 5px;
  }
}
.about .about__note {
  background-color: #f5f5f5;
  border: 1px solid #aebcbc;
  height: fit-content;
  padding: 15px;
}
.about .about__note h3 {
  background-color: #f5f5f5;
  padding: 0;
  border-bottom: 1px solid #dbdddc;
}

.facades {
  margin-top: 20px;
}
.facades img {
  border-radius: 0 0 0 50%;
  margin-bottom: 50px;
}
@media (max-width: 1200px) {
  .facades img {
    border-radius: 0 0 0 40%;
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .facades img {
    border-radius: 0 0 0 30%;
    margin-bottom: 30px;
    display: none;
  }
}
@media (max-width: 375px) {
  .facades img {
    border-radius: 0 0 0 20%;
    margin-bottom: 20px;
  }
}
.facades h3 {
  background-color: #a4c8d4;
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px;
  padding-left: 10px;
  margin: 0;
}
@media (max-width: 768px) {
  .facades h3 {
    font-size: 13px;
    padding: 4px;
    padding-left: 8px;
  }
}
@media (max-width: 375px) {
  .facades h3 {
    font-size: 12px;
    padding: 3px;
    padding-left: 5px;
  }
}

.facades__article {
  margin-left: 50px;
}
@media (max-width: 1200px) {
  .facades__article {
    margin-left: 40px;
  }
}
@media (max-width: 768px) {
  .facades__article {
    margin-left: 20px;
  }
}
@media (max-width: 375px) {
  .facades__article {
    margin-left: 10px;
  }
}

.facades__ads {
  font-size: 16px;
  width: 214px;
  padding: 10px 20px;
  border: 1px solid #dddddf;
}
@media (max-width: 768px) {
  .facades__ads {
    font-size: 14px;
    width: 100%;
    padding: 8px 16px;
  }
}
@media (max-width: 375px) {
  .facades__ads {
    font-size: 12px;
    width: 160px;
    padding: 6px 12px;
  }
}
.facades__ads p {
  color: #40a9be;
  margin: 0;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .facades__ads p {
    margin-bottom: 20px;
  }
}
@media (max-width: 375px) {
  .facades__ads p {
    margin-bottom: 15px;
  }
}
.facades__ads ul {
  padding-left: 10px;
}
@media (max-width: 768px) {
  .facades__ads ul {
    padding-left: 50px;
  }
}
@media (max-width: 375px) {
  .facades__ads ul {
    padding-left: 8px;
  }
}
.facades__ads li {
  cursor: pointer;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 13px;
}
@media (max-width: 768px) {
  .facades__ads li {
    font-size: 12px;
    max-width: 200px;
    text-align: left;
  }
}
@media (max-width: 375px) {
  .facades__ads li {
    font-size: 11px;
    margin-bottom: 4px;
  }
}
.facades__ads .active {
  background-color: #cccccc;
}
@media (max-width: 375px) {
  .facades__ads .active {
    background-color: #e0e0e0;
  }
}