.burger-menu {
  display: none;
  position: absolute;
  top: 50px;
  right: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;

  width: 40px;
  height: 40px;
  .burger-icon {
    width: 30px;
    height: 4px;
    background-color: #333;
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: #333;
      border-radius: 5px;
      transition: all 0.3s ease;
    }

    &::before {
      top: -10px;
      left: -10px;
    }

    &::after {
      top: 10px;
      left: -10px;
    }
  }

  &.active .burger-icon {
    background-color: transparent;
    opacity: 0.3;
  }

  &.active .burger-icon::before {
    transform: rotate(45deg);
    top: 0;
  }

  &.active .burger-icon::after {
    transform: rotate(-45deg);
    top: 0;
  }

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }
}
